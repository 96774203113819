import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Modal from 'react-modal';

//RESOURCES
import './../css/header.scss';
import './../css/modal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import logo from './../images/logo-fazenda-serra-azul.png';

//CUSTOM
Modal.setAppElement('#root');
const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class Header extends Component {
    constructor() {
        super();
        this.state = {
            modalIsOpen: false
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    };

    closeModal() {
        this.setState({ modalIsOpen: false });
    };

    render() {
        return (
            <>
                <Navigator className={this.props.className} modal={this.openModal} />
                <Video isOpen={this.state.modalIsOpen} close={this.closeModal}/>
            </>
        )
    }
}

class Navigator extends Component {
    constructor() {
        super();
        this.state = {
            menu: ' '
        }
    }

    openMenu() {
        this.setState({ menu: 'open' });
    };

    closeMenu() {
        this.setState({ menu: ' ' });
    };


    render() {
        return (
            <header id="header" className={(this.props.className === 'white' || this.props.className === 'transparence' ? this.props.className : '') + ' section reset'}>
                <div className="content">
                    <div className="flex">
                        <figure className="logo">
                            <NavLink to="/">
                                <img src={logo} alt="Fazenda SerrAzul" title="Fazenda SerrAzul" />
                            </NavLink>
                        </figure>
                        <nav>
                            <button onClick={() => { this.openMenu() }}>
                                <FontAwesomeIcon icon={faBars} />
                                Menu
                            </button>
                            <ul className={this.state.menu}>
                                <li className="mobile">
                                    <button onClick={() => { this.closeMenu() }}>
                                        <FontAwesomeIcon icon={faWindowClose} />
                                        Fechar
                                    </button>
                                    <figure>
                                        <NavLink to="/">
                                            <img src={logo} alt="Fazenda SerrAzul" title="Fazenda SerrAzul" />
                                        </NavLink>
                                    </figure>
                                </li>
                                <li>
                                    <a href="#" title="Assita ao nosso vídeo 360º" onClick={this.props.modal}>
                                        Assista ao nosso<br />
                                        Vídeo 360º
                                    </a>
                                </li>
                                <li>
                                    <NavLink to="/empreendimento" title="Empreendimento" onClick={this.closeMenu.bind(this)}>Loteamento</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/localizacao" title="Localização" onClick={this.closeMenu.bind(this)}>Localização</NavLink>
                                </li>
                                <li>        
                                     <NavLink to="/Realizacao" title="Localização" onClick={this.closeMenu.bind(this)}>Realização</NavLink>
                                </li>
                                <li>
                                    <a href="https://www.senparnet.com.br/prod/login/" rel="noopener noreferrer nofollow" title="Área do Corretor" target="_blank" className="corretor">
                                        Área do Corretor
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        )
    }
}

class Video extends Component {
    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.close}
                style={modalStyle}
            >
                <section id="video360" className="reset">
                    <button onClick={this.props.close} className="close">Fechar</button>
                    <p>
                        <strong>Assista ao nosso vídeo 360°</strong>
                    </p>
                    <iframe title="Vídeo 360" width="560" height="315" src="https://www.youtube.com/embed/GtHtXH2uTf0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </section>
            </Modal>
        )
    }
}

export default Header;